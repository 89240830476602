import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import ContactUsScreen from 'components/screens/ContactUsScreen/ContactUsScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <ContactUsScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query GetContactUs {
    strapiContactUs {
      description
      phone
      email
    }
  }
`;

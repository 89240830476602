import React from 'react';
import { Helmet } from 'react-helmet';

const ContactUsScreen = ({data}) => {

  return (
    <>
      <Helmet
        title="Contact Us"
        />
      <div className="px-12 max-w-lg m-auto md:max-w-lg md:px-6 lg:max-w-lg lg:px-8">
        <div className='text-center md:text-left mt-6 mb-16'>
          <h1 className='text-4xl font-bold font-heading uppercase'>Contact Us</h1>
        </div>
        
        <div className="grid">
          <p>{data.strapiContactUs.description}</p>
          <p>{data.strapiContactUs.phone}</p>
          <p>{data.strapiContactUs.email}</p>
        </div>
        
      </div>
      </>
  );
};

export default ContactUsScreen;